.elizabeth-about {
  width: 90%;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 1s, transform 1s;
  margin-top: -10%;
  margin-bottom: 10%;
}

.elizabeth-about.show {
  opacity: 1;
  transform: translateY(0);
}

.content {
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  color: #fff;
  padding-top: 130px;
  padding-bottom: 130px;
}

.question {
  font-size: 5vh;
  font-weight: bold;
  color: #0e060a;
  font-family: 'Etna sans-serif'
}

.paragraph {
  flex-basis: 55%; /* Adjusted to accommodate the text */
  margin-right: auto;
  
}

.paragraph p {
  font-size: 18px;
  line-height: 1.5;
  color: #333333;
  text-align: left;
  font-family:'Jost';
}
.image-container {
  flex-basis: 45%; /* Adjusted to accommodate the image */
  overflow: hidden;
  transform: rotate(6deg);
}

.random-image {
  width: 65%;
  height: auto;
  max-width: 100%;
  transform: rotateY(180deg);
  margin-left: 18%;
}

.intro-heading {
  /* Styles for the first heading */
  display: flex;
  font-size: 1.5vh;
  color: #333333;
  text-align: left;
  font-family:'Jost';
}

.about-intro{
  margin-top: 30px;
  font-size: 50px;
  line-height: 1.5;
  font-family:'DM Serif Display';
  text-align: left;
  text-transform: capitalize;
  font-weight: bolder;
}




@media (max-width: 768px) {
  .content {
    flex-direction: column;
    align-items: center;
  }

  .about-intro{
    font-size: 2.2 vh;
  }

  .question {
    font-size: 3vh;
  }

  .image-container {
    flex-basis: 25%;
  }

  .random-image {
    margin-left: 18%;
  }

  .paragraph {
    flex-basis: 100%;
    
  }

  .paragraph p {
    font-size: 2vh;
    text-align: center;
  }

  .arrow-icon{
   margin-top: 10%;
  opacity: 0;

  }
  .intro-heading{
    font-size: 3vh;
    margin-top: 10%;
    margin-bottom: -20%;
    margin-left: 1%;
  }
}

@media (max-width: 480px) {
  .content {
    flex-direction: column;
    align-items: center;
  }

  .about-intro{
    font-size: 2.2 vh;
  }
  .question {
    font-size: 3vh;
  }

  .image-container {
    flex-basis: 25%;
  }

  .random-image {
    margin-left: 18%;
  }

  .paragraph {
    flex-basis: 100%;
  }

  .paragraph p {
    font-size: 2vh;
    text-align: center;
  }

  .arrow-icon{
    margin-top: 10%;
   opacity: 0;
 
   }
   .intro-heading{
     font-size: 3vh;
     margin-top: 10%;
     margin-bottom: -20%;
     margin-left: 1%;
   }
}
