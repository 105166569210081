
/* ElizabethIntroduction.css */
.elizabeth-introduction {
  /* background: linear-gradient(190deg, #ff5757, #8c52ff); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
  position: relative;
  min-height: 90vh;
  display: flex; /* Use flexbox for layout */
  justify-content: space-between; /* Align items with space between */
  align-items: center;
  padding: 15px; /* Adjust padding as needed */
 
  
  }
  
  .elizabeth-image-container {
    flex: 1;
   
  
  }
  
  .img-fluid {
    max-width: 95%;
    height: auto;
    margin-bottom: 100px;

  }
  
  /* .background-image {
    margin-bottom: 9%;
    padding-left: 19%;
    object-fit: cover;
    z-index: 1;
    max-width: 100%; 
  }
   */
  .elizabeth-content-container {
    flex: 2; /* Takes 2/3 of the space */
    padding: 20px; /* Adjust padding as needed */
    margin-top: -33vh;
    
    display: flex; /* Use flexbox to align items */
    flex-direction: column; /* Stack items vertically */
    justify-content: flex-start; /* Align items at the start of the container */
    align-items: flex-start; /* Align items at the start of the cross axis */
}
    
 
  

  .introduction-heading {
    /* Styles for the first heading */
    display: flex;
    font-size: 2.5vh;
    color: #333333;
    text-align: left;
    font-family:'Jost';
  }
  
  .passionate-heading {
    /* Styles for the second heading */
    font-size: 2vh;
    color: #4F4F4F;
    text-align: left;
    line-height: 5vh;

  }
  
  .experience-heading {
    /* Styles for the third heading */
    font-size: 2.5vh;
    color: #333333;
    font-family: 'Jost';
    margin-right: 0.5rem;

  }
  
  .resume-heading {
    /* Styles for the fourth heading */
    font-size: 2.5vh;
    color: #FF826E;
    font-family: 'Jost';
    text-decoration: underline;
    
  }
  
 
  
  
  .name-heading {
    margin-top: 30px;
    font-size: 50px;
    line-height: 1.5;
    font-family:'DM Serif Display';
    text-align: left;
    text-transform: capitalize;
    font-weight: bolder;
    
  }


  .email-button {
    background-color: transparent;
    color: #FF826E;
    border: 2px solid #FF826E;
    padding: 10px 20px;
    font-size: 1rem;
    border-radius: 1px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    margin-right: 50%; /* Move the button to the left */
  }
  
  

 /* Extra small devices (phones, 576px and down) */
@media (max-width: 576px) {
  .intro-heading {
    
    font-size: 1.9vh;
  }
  .name-heading {
    
    font-size: 25px;
  }


  .elizabeth-image-container{
    margin-bottom: -50%;
    width: 100%;
  }
}

/* Small devices (landscape phones, 768px and down) */
@media (max-width: 768px) {
  .intro-heading {
    font-size: 1.9vh;
  }

  .name-heading {
    
    font-size: 25px;
  }

  .elizabeth-image-container{
    margin-bottom: -50%;
  }

  
 
}

/* Medium devices (tablets, 992px and down) */
@media (max-width: 992px) {
  .intro-heading {
    font-size: 2.2vh;
  }
  .name-heading {
    
    font-size: 30px;
  }
  .elizabeth-image-container{
    margin-bottom: -20%;
  }

  
}
