@import url(https://fonts.googleapis.com/css?family=Roboto:300,400);
@import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.4.0/css/font-awesome.min.css);

.main-client-content{
  width: 100%;
  height: 100%;
  min-height: 100vh;
}

.client-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  /* background: url('/public/img/client2.webp') center/cover no-repeat;  */
  padding-bottom: 100px;
  
}


.profile-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 70px;
}

figure.snip1390 {
  font-family: 'Etna sans-serif';
  position: relative;
  overflow: hidden;
  margin: 10px;
  min-width: 230px;
  max-width: 315px;
  width: 100%;
  color: #ffffff;
  text-align: center;
  font-size: 16px;
  background-color:#ff5780; /* Adjust the alpha value (0.8) for transparency */
  padding: 30px;
  background-image: linear-gradient(-25deg, rgba(0, 0, 0, 0.2) 0%, rgba(255, 255, 255, 0.1) 100%);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 20px;
  transition: transform 0.2s;
  margin: 0;
}

figure.snip1390:hover {
    transform: scale(1.05); /* Increase the scale on hover for a zoom-in effect */
  }



figure.snip1390 *,
figure.snip1390 *:before,
figure.snip1390 *:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}

figure.snip1390 figcaption {
  width: 100%;
}

figure.snip1390 h2,
figure.snip1390 h4,
figure.snip1390 blockquote {
  margin: 0;
  margin-bottom: 0px;
}
figure.snip1390 blockquote {
  
  margin-bottom: -15px;
}

figure.snip1390 h2,
figure.snip1390 h4 {
  font-weight: 300;
}

figure.snip1390 h2 {
  color: #ffffff;
}

figure.snip1390 h4 {
  padding-top: 10px;
  color: #ffffff;
}

figure.snip1390 blockquote {
  font-size: 1em;
  padding: 45px 20px 40px 50px;
  margin-top: 30px;
  border: none; /* Remove the border */
  /* background-image: linear-gradient(-25deg, rgba(0, 0, 0, 0.2) 0%, rgba(255, 255, 255, 0.1) 100%); */
  /* border-radius: 5px;
  box-shadow: inset -1.4px -1.4px 2px rgba(0, 0, 0, 0.3); */
  text-align: left;
  position: relative;
  letter-spacing: 1px;
}



figure.snip1390 blockquote:before {
  font-family: 'Etna sans-serif';
  content: "\201C";
  position: absolute;
  font-size: 70px;
  opacity: 0.25;
  font-style: normal;
  top: 0px;
  left: 20px;
}

figure.snip1390 .profile {
  width: 100px;
  border-radius: 50%;
  display: inline-block;
  box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.4);
  margin-bottom: 10px;
  border: solid 5px #A6A57A;
}

.main-header-conainer {
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
  height: 100vh; /* 100% of the viewport height */
  background-color:#ffffff;
  padding-top: 100px;
}
  
.satisfied-clients-heading {
        font-size: 2vw;
        font-weight: bold;
        color: #0e060a;
        letter-spacing: 0.5vw;
        padding-top: 50px;
        padding-bottom: 20px;
        font-family: "Etna sans-serif";
        text-align: left;
        padding-left: 30px;
        margin-top: 10px;
        
    }

.gold-stars i.fa-star {
        color: gold;
      }


.logo-container{
  margin: 10px;
  padding-bottom: 20px;
  overflow: hidden;
}

.img-container{
  padding: 40px 0;
}
.img-container img{
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  text-align: center;
}

@media (max-width: 768px) {
        .main-header-conainer {
          padding-top: 130%; 
        }
        .satisfied-clients-heading {
            font-size: 7vw;
            padding-bottom: 30px;
        }

       
      
    }