/* Logo.css */
.logo {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FF826E;
  z-index: 9999;
  opacity: 1;
  transform: scale(1);
  transition: opacity 1s, transform 0.5s;
}

.soon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  
}

.logo-container {
  text-align: center;
  color: black;
}

.monogram {
  font-size: 204px; /* Adjust the size as needed */
  font-weight: bold;
  font-family: "cursive";
  transform: scaleX(1.5); /* Adjust the scaleX value to elongate the "R" */
  display: inline-block; /* Ensure the transformation affects the letter properly */
  font-style: normal;
}


.name {
  font-size: 20px; /* Adjust the size as needed */
  font-weight: bold;
  font-family: Jost, serif;
  letter-spacing: 25px;
}

/* Media query for screens smaller than 768px (typical mobile screens) */
@media (max-width: 768px) {
  .monogram {
    font-size: 104px;
  }
  
  .name {
    font-size: 12px;
    letter-spacing: 10px;
  }
}

.logo.hide {
  opacity: 0;
  pointer-events: none;
  transform: scale(0.8);
}
