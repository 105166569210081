.header {
  background: #ffffff;
  padding: 1.5vh 0; /* Adjust padding for responsiveness */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s, transform 0.3s;
  position: sticky;
  top: 0;
  z-index: 100;
  transition: transform 0.3s, opacity 0.3s;
  padding: 1vh 2vw;
}
.header .container, 
.navbar .container {
  width: 100% !important;
 
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.header.visible {
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  opacity: 1;
}

.header.hidden {
  transform: translateY(-100%);
  box-shadow: none;
  opacity: 0;
}


.header:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  transform: translateY(-2px);
}

.navbar .nav-link {
  font-family: 'Jost', sans-serif;
  color: #333; /* Set link color */
}

.nav-list {
  display: flex;
  justify-content: center;
  padding: 0;
  margin-top: -10px;

}

.nav-list li {
  margin: 0;
}

.nav-list li a {
  color: #0e060a;
 
  font-weight: normal;
  font-size: 1.7vh;
  font-family: 'Etna sans-serif';
  padding: 1vh 2vw; /* Adjust padding for responsiveness */
  border-radius: 20px;
  transition: background 0.3s, font-size 0.3s; /* Add transition for font-size */
}

.nav-list li a:hover {
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(5px);
}



.header-left {
  display: flex;
  align-items: center;
  padding-left: 20px; 
  position: relative; /* Add position relative */
  z-index: -1; /* Bring it forward */
  margin-bottom: -17px;
}

.header-left span {
  color: #0e060a;
  font-weight: bold;
  font-size: 2vh;
  font-family: 'Etna sans-serif';
  margin-right: 20px; /* Adjust the margin for better spacing */
  letter-spacing: 0.5vh;
}

.active-nav {
  color: #FF826E !important;
}

@media (max-width: 768px) {
  .header {
    padding: 1vh 1vw; /* Adjust padding for responsiveness */
  }

  .header-left {
    padding-left: 10px; /* Adjust the padding for better spacing on small screens */
  }

  .nav-list {
    list-style: none;
    display: flex;
    justify-content: flex-end;
    margin: 0;
    padding: 0;
   
  }

  .nav-list li a {
    font-size: 1.2vw; /* Adjust the font size for better readability on small screens */
    padding: 1vh 1vw; /* Adjust padding for better spacing */
  }

  .header-left span {
    font-size: 1.5vh; /* Adjust the font size for better readability on small screens */
    padding: 0.5vh 0.5vw;
  }

}

/* Add another media query for even smaller screens if needed */
@media (max-width: 480px) {
  .nav-list {
    list-style: none;
    display: flex;
    justify-content: flex-end;
    margin: 0;
    padding: 0;
  }

  .nav-list li a {
    font-size: 1.5vw; /* Adjust the font size for better readability on small screens */
    padding: 1vh 1.2vw; /* Further adjust the font size for better readability on smaller screens */
    letter-spacing: 0.5vh;
   
  }

  .header-left span {
    font-size: 1.5vh; /* Adjust the font size for better readability on small screens */
    margin-left: -4%;
    
  }
  .contact-info .phone span,
  .contact-info .email span {
    font-size: 1.4vh; /* Reduced font size for small screens */
  }
}

