.service-container {
  width: 85%;
  height: 100%;
  position: relative;
  min-height: 100vh;
  align-items: center;
}

section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.column {
  width: calc(100% / 3);
  padding: 0 1em 1em 1em;
  text-align: left;
  box-sizing: border-box;
}

.section-heading-left {
  font-size: 3.5vh;
  font-weight: bold;
  color: black;
  font-family: 'Etna sans-serif';
  letter-spacing: 0.5vh;
  padding-top: 10px;
  text-align: left; /* Align heading to the left */
  position: relative;
}

.flex-typography{
  font-size: 36px !important;
}

@media screen and (max-width: 992px) {
  .column {
    width: calc(100% / 2);
  }
 
  .flex-typography{
    font-size: 36px !important;
  }
}

@media screen and (max-width: 768px) {
  .column {
    width: 100%;
  }
  .flex-typography{
    font-size: 20px !important;
  }
}
@media (max-width: 576px) {
  .flex-typography {
    font-size: 2.2vh; /* Adjust font size on small screens */
  }
  .flex-typography{
    font-size: 20px !important;
    
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .flex-typography {
    font-size: 2.2vh; /* Adjust font size on medium screens */
  }
  .flex-typography{
    font-size: 20px !important;
  }
}
